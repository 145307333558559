<template>
  <v-container>
    <v-skeleton-loader
        v-if="!locale"
        type="card-heading"
    ></v-skeleton-loader>
    <div v-else class="headline mb-5">
      {{ $t('PAYMENT') }}
    </div>

    <v-alert
      v-if="error"
      type="error"
      outlined
    >{{ $t('ERROR_INVALID_LINK_MESSAGE') }}</v-alert>

    <v-skeleton-loader
      class="mx-auto"
      type="card-heading"
      v-else-if="attrs.loading"
    ></v-skeleton-loader>

    <v-alert
      v-else-if="!attrs.loading && attrs.isTemporaryStatus"
      type="info"
      tile
      dismissible
      outlined
      border="left"
    >
      <i18n path="TRANSACTION_MESSAGE_TEMPORARY" tag="span">
        <template v-slot:date v-if="transactionDate">
          <strong>
            {{ transactionDate | dateTime }}
          </strong>
        </template>
      </i18n>
    </v-alert>

    <v-alert
      v-else
      :type="attrs.readonly ? 'success' : 'error'"
      tile
      dismissible
      outlined
      border="left"
    >
      <i18n :path="attrs.readonly ? 'TRANSACTION_MESSAGE_SUCCESS' : 'TRANSACTION_MESSAGE_ERROR'" tag="span">
        <template v-slot:date v-if="transactionDate">
          <strong>
            {{ transactionDate | dateTime }}
          </strong>
        </template>
      </i18n>
    </v-alert>

    <v-form v-model="attrs.valid" ref="form">
      <v-row>
        <v-col cols="12" md="9" class="order-2 order-md-1">
          <InvoiceTable
            v-bind="attrs"
            disable-deletion
            disable-amount-change
          ></InvoiceTable>
        </v-col>

        <v-col cols="12" md="3" class="order-1 order-md-2">
          <CheckoutSummary
            v-bind="attrs"
            v-on="on"
            hide-credit-notes
          ></CheckoutSummary>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import oneOffPaymentService from '@/services/oneOffPaymentService';
import basketService from '@/services/basketService';
import invoiceMapper from '@/mappers/invoiceMapper';

import dateMixin from '@/mixins/dateMixin';
import localeMixin from '@/mixins/localeMixin';
import { OneOffSuccessfulPaymentStatusesEnum, TemporaryPaymentStatusesEnum } from '@/enums/paymentStatusesEnum';

import InvoiceTable from '@/components/debtor/checkout/InvoiceTable';
import CheckoutSummary from '@/components/debtor/checkout/CheckoutSummary';

import { mapState } from 'vuex';

export default {
  components: {
    InvoiceTable,
    CheckoutSummary
  },
  mixins: [dateMixin, localeMixin],
  data () {
    return {
      attrs: {
        loading: false,
        paymentLoading: false,
        creditor: undefined,
        debtor: undefined,
        valid: false,
        readonly: false,
        isTemporaryStatus: false,
        oneOffMode: true
      },
      on: {
        pay: this.startPaymentProcess
      },
      error: false,
      invoices: [],
      oneOffPaymentOperationId: undefined,
      transactionDate: undefined
    };
  },
  computed: {
    ...mapState(['locale']),
    ...mapState({
      basket: state => state.basket
    })
  },
  async beforeMount () {
    this.attrs.loading = true;
    try {
      const { data } = await oneOffPaymentService.getPaymentOperationByReference(this.$route.params.id);
      if (!this.locale) {
        await this.updateApplicationLanguageFromCreditorPreferredLanguage(data.creditor.preferredLanguage);
      }
      this.invoices = invoiceMapper.mapAll(data.documents.map(({ invoice }) => invoice));
      this.attrs.creditor = data.creditor;
      this.attrs.debtor = data.debtor;
      this.oneOffPaymentOperationId = data.oneOffPaymentOperation.id;
      const basket = basketService.createBasketFromPaymentOperation(data);
      await this.$store.dispatch('setBasket', basket);
      this.$store.dispatch('setOneOffDebtor', data.oneOffPaymentOperation.debtor);
      this.attrs.readonly = Object.values(OneOffSuccessfulPaymentStatusesEnum).includes(data.status);
      this.attrs.isTemporaryStatus = Object.values(TemporaryPaymentStatusesEnum).includes(data.status);
      this.transactionDate = data.creationDate;
    } catch (err) {
      this.error = true;
    } finally {
      this.attrs.loading = false;
    }
  },
  methods: {
    async startPaymentProcess (paymentMean) {
      this.attrs.paymentLoading = true;
      try {
        const { data } = await oneOffPaymentService.createPaymentOperation({
          id: this.oneOffPaymentOperationId,
          basket: this.basket,
          creditorId: this.attrs.creditor.id,
          debtorId: this.attrs.debtor.id,
          languageCode: this.$t('LANG_ID'),
          paymentMean
        });
        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        } else {
          this.attrs.paymentLoading = false;
        }
      } catch (err) {
        this.attrs.paymentLoading = false;
        this.handleError(err);
      }
    }
  }
};
</script>
