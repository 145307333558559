<template>
  <v-container>
    <v-skeleton-loader
        v-if="!locale"
        type="card-heading"
    ></v-skeleton-loader>
    <div v-else class="headline mb-5">
      {{ $t('PAYMENT') }}
    </div>

    <v-alert
      v-if="error"
      type="error"
      outlined
    > {{ this.getErrorMessage(returnedError) }} </v-alert>
    <v-form v-model="attrs.valid" ref="form" v-else>
      <v-row>
        <v-col cols="12" md="9" class="order-2 order-md-1">
          <InvoiceTable
            v-bind="attrs"
            disable-deletion
            disable-amount-change
          ></InvoiceTable>
        </v-col>

        <v-col cols="12" md="3" class="order-1 order-md-2">
          <CheckoutSummary
            v-bind="attrs"
            v-on="on"
            hide-credit-notes
          ></CheckoutSummary>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import oneOffPaymentService from '@/services/oneOffPaymentService';
import basketService from '@/services/basketService';
import invoiceMapper from '@/mappers/invoiceMapper';

import InvoiceTable from '@/components/debtor/checkout/InvoiceTable';
import CheckoutSummary from '@/components/debtor/checkout/CheckoutSummary';
import { ErrorMessagesCorrelationMap } from '@/enums/errorMessageCorrelationMap';

import { mapState } from 'vuex';
import localeMixin from '@/mixins/localeMixin';
import globalMixin from '@/mixins/globalMixin';

export default {
  components: {
    InvoiceTable,
    CheckoutSummary
  },
  mixins: [localeMixin, globalMixin],
  data () {
    return {
      attrs: {
        loading: false,
        paymentLoading: false,
        creditor: undefined,
        oneOffMode: true,
        debtor: undefined,
        valid: false,
        returnedError: undefined
      },
      on: {
        pay: this.startPaymentProcess
      },
      invoices: [],
      error: false
    };
  },
  computed: {
    ...mapState(['locale']),
    ...mapState({
      basket: state => state.basket
    })
  },
  async beforeMount () {
    this.attrs.loading = true;
    try {
      const { data } = await oneOffPaymentService.getPaymentOperation(this.$route.params.id);
      const date = new Date();
      const currentDate = date.toJSON();

      if (currentDate > data.paymentDeadline) {
        this.error = true;
        this.returnedError = 'Payment deadline has passed';
        return;
      }

      const invoice = invoiceMapper.mapOne(data.invoice);
      if (!this.locale && invoice.language != null && ['FR', 'EN', 'NL', 'DE', 'IT'].includes(invoice.language)) {
        await this.updateApplicationLanguageFromCreditorPreferredLanguage(invoice.language);
      }
      if (!this.locale && invoice.language == null) {
        await this.updateApplicationLanguageFromCreditorPreferredLanguage(data.creditor.preferredLanguage);
      }
      this.invoices = [invoice];
      if (['PAID', 'SETTLED'].includes(invoice.status) && data.paymentOperation?.transactionReference) {
        return await this.redirectUserToTransaction(data.paymentOperation.transactionReference);
      }
      this.attrs.creditor = data.creditor;
      this.attrs.debtor = data.debtor;
      const basket = basketService.createBasket({
        invoices: this.invoices,
        accountId: this.attrs.debtor.billingAccountId,
        creditorId: this.attrs.creditor.id
      });
      await this.$store.dispatch('setBasket', basket);
      this.$store.dispatch('setOneOffDebtor', data.debtor);
    } catch (err) {
      this.returnedError = err.response.data.errors[0];
      this.error = true;
    } finally {
      this.attrs.loading = false;
    }
  },
  methods: {
    async startPaymentProcess (paymentMean) {
      this.attrs.paymentLoading = true;
      try {
        const { data } = await oneOffPaymentService.createPaymentOperation({
          id: this.$route.params.id,
          basket: this.basket,
          creditorId: this.attrs.creditor.id,
          debtorId: this.attrs.debtor.id,
          languageCode: this.$t('LANG_ID'),
          paymentMean
        });
        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        } else {
          this.attrs.paymentLoading = false;
        }
      } catch (err) {
        this.attrs.paymentLoading = false;
        this.handleError(err);
      }
    },
    redirectUserToTransaction (transactionReference) {
      return this.$router.push({
        name: 'OneOffTransaction',
        params: {
          id: transactionReference
        }
      });
    },
    getErrorMessage (errorReturned) {
      return ErrorMessagesCorrelationMap.has(errorReturned) ? this.messageToLocal(ErrorMessagesCorrelationMap.get(errorReturned)) : this.$t('ERROR_INVALID_LINK_MESSAGE');
    }
  }
};
</script>
